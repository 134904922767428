import React from 'react'

function Contact() {
    return (
        <div style={{
            height: '80px', display: 'flex',
            flexDirection: 'column', alignItems: 'center',
            justifyContent: 'center', fontSize:'1rem'
        }}><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
           <p>Contact Us:<br></br></p>
           <p>For any delivery related query : 2100 - 200 -25552 (Mon - Fri)
            del@abc.com (24*7)
           </p><br></br>
           <p>For any quality related query : 2100 - 300 -25552 (Mon - Fri)
            qua@abc.com (24*7)
           </p><br></br>
           
        </div>
    )
}

export default Contact
